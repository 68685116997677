import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import UI from '../../common/UI';
import config from '../config';
import useLocale from '../../common/useLocale';

export interface TurnstileProps {
  challenge: string;
  onSuccess: (token: string) => void;
}

const Turnstile = ({ challenge, onSuccess }: TurnstileProps) => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  const renderedRef = useRef(false);

  const render = useCallback(() => {
    if (renderedRef.current) {
      return;
    }

    window.turnstile?.render('#turnstile-widget', {
      sitekey: config.turnstileSiteKey,
      cData: challenge,
      callback: onSuccess,
      language: locale,
    });

    renderedRef.current = true;
  }, [onSuccess, challenge, locale]);

  useEffect(() => {
    (window as any).onloadTurnstileCallback = () => {
      render();
    };

    const script: HTMLScriptElement = document.createElement('script');
    script.setAttribute('nonce', window.NONCE_ID);
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback';

    if (!window.turnstile) {
      document.head.insertBefore(script, document.head.childNodes[0]);
    } else {
      render();
    }
  }, [render]);

  return (
    <UI.FlexContainer
      sc={{ alignItems: 'center', justifyContent: 'center', direction: 'column' }}
      style={{ minHeight: '100%' }}
    >
      <UI.Legend sc={{ textAlign: 'center', mb: 3 }}>
        {t('common:validation.verifying_human')}
      </UI.Legend>
      <div id="turnstile-widget" style={{ minHeight: 70 }} />
    </UI.FlexContainer>
  );
};

export default Turnstile;
