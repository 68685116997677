import { ChevronLeft } from 'react-feather';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AddOrderStatus } from '__generated__/graphql';
import { CheckoutProps } from './CheckoutProvider';
import { CheckoutStep } from './helpers';
import { EmbedContext } from '../Common/EmbedProvider';
import CheckoutTimers from './CheckoutTimers';
import DetailsForm from './Details/DetailsForm';
import ExtrasForm from './Extras/ExtrasForm';
import FundraisingForm from './Fundraising/FundraisingForm';
import InvitationCodeForm from './InvitationCodeForm';
import Layout, { TitleBar } from '../Common/Layout';
import Pager from './Pager';
import PaymentForm from './Payment/PaymentForm';
import PersonalisationForm from './Personalisation/PersonalisationForm';
import Queue from './Queue';
import StateContext from './StateContext';
import TeamForm from './Team/TeamForm';
import TicketsForm from './Tickets/TicketsForm';
import UI from '../../common/UI';
import UserInfo from './UserInfo';
import useCheckoutUrlState from './useCheckoutUrlState';
import useDocumentTitle from '../../common/useDocumentTitle';
import useLocale from '../../common/useLocale';

const CheckoutPage = ({
  event,
  ticketCategories,
  checkoutSteps,
  activeStepIndex,
  nextRegistration,
  valid,
  completeCheckout,
  loading,
  error,
  status,
  preselectedCouponCode,
  preselectedCharity,
  queue,
}: CheckoutProps) => {
  const { t } = useTranslation();
  const { formatNumber, locale } = useLocale();
  const history = useHistory();
  const [urlState] = useCheckoutUrlState();
  const { embedded } = useContext(EmbedContext);

  // Need to append search to all links because there might be a source URL in the URL
  const { search } = useLocation();

  useDocumentTitle(event.title);

  const { scrollTop } = useContext(EmbedContext);
  const { session } = useContext(StateContext);

  const [{ paymentStatus, ticketCategoryId }] = useCheckoutUrlState();

  const handleSubmit = () => {
    if (nextRegistration()) {
      scrollTop();
    } else if (activeStepIndex + 1 < checkoutSteps.length && valid[activeStepIndex]) {
      history.push(`/${event.id}/${checkoutSteps[activeStepIndex + 1]}${search}`);
      scrollTop();
    } else if (valid[activeStepIndex]) {
      completeCheckout().catch(() => scrollTop());
    }

    return true;
  };

  useEffect(() => {
    // Scroll up to the error message
    if (status === AddOrderStatus.START_PAYMENT_FAILED) {
      scrollTop();
    }
  }, [status, scrollTop]);

  const activeStepName = checkoutSteps[activeStepIndex];

  const invitationCode = event.invitation_code;

  const intro = invitationCode?.invitation.description || event.description;

  const ticketCategory = ticketCategories.filter((ticketCategory) => ticketCategory.id === ticketCategoryId)[0];

  const ticketsForSaleCount = ticketCategories.reduce(
    (sum, ticketCategory) => sum + ticketCategory.tickets_for_sale.length,
    0,
  );

  if (activeStepIndex === -1) {
    return <Redirect to={`/${event.id}${search}`} />;
  }

  if (activeStepIndex > 0 && !valid[activeStepIndex - 1]) {
    return <Redirect to={`/${event.id}/${checkoutSteps[activeStepIndex - 1]}${search}`} />;
  }

  return (
    <Layout event={event} embedded={embedded}>
      {!paymentStatus && intro && (
        <>
          <UI.HR style={{ borderColor: 'rgba(0, 0, 0, 0.10)' }} />
          <UI.Div sc={{ color: 'gray.800' }}>
            <UI.HTML html={intro} />
          </UI.Div>
        </>
      )}

      {paymentStatus && (
        <>
          <UI.Div>
            {paymentStatus === 'pending' && (
              <UI.Warning>
                {t('common:payments.payment_pending')}
              </UI.Warning>
            )}
            {paymentStatus !== 'pending' && (
              <UI.Error>
                {t('common:payments.payment_failed')}
              </UI.Error>
            )}
          </UI.Div>
        </>
      )}

      {queue && (
        <Queue queue={queue} />
      )}

      {!queue && (
        <>
          {ticketsForSaleCount > 0 && (
            <UI.Form id="CheckoutForm" name="checkout" onSubmit={() => handleSubmit()}>
              <TitleBar>
                <UI.Div>
                  {activeStepIndex > 0 && (
                    <UI.FadeIn>
                      <UI.Link to={`/${event.id}/${checkoutSteps[activeStepIndex - 1]}${search}`} sc={{ small: true }}>
                        <UI.Icon>
                          <ChevronLeft />
                        </UI.Icon>
                        {' '}
                        {t('back')}
                      </UI.Link>
                    </UI.FadeIn>
                  )}
                </UI.Div>
                <UI.Div>
                  {(activeStepName === CheckoutStep.Tickets && urlState.ticketCategoryId && ticketCategory?.title)
                    || t(`checkout_steps.${activeStepName}`)}
                </UI.Div>
                <UI.Div sc={{ small: true }}>
                  {activeStepIndex + 1}
                  {' / '}
                  {checkoutSteps.length}
                </UI.Div>
              </TitleBar>

              <UI.Card id="CheckoutContainer" sc={{ hasPanels: true, active: true }} key={activeStepName}>
                {session.showTimers && (
                  <CheckoutTimers />
                )}

                {error && (
                  <UI.Div>
                    <UI.ServerErrors error={error} attributes={{ 'team.title': t('common:team_picker.team_name') }} />
                  </UI.Div>
                )}

                {status === AddOrderStatus.START_PAYMENT_FAILED && (
                  <UI.Div>
                    <UI.Error>
                      <UI.Strong sc={{ block: true }}>
                        {t('common:payments.could_not_start_payment')}
                      </UI.Strong>
                      {t('common:payments.try_again_in_a_minute')}
                    </UI.Error>
                  </UI.Div>
                )}

                {activeStepName === CheckoutStep.Tickets && (
                  <TicketsForm preselectedCharity={preselectedCharity} preselectedCouponCode={preselectedCouponCode} />
                )}
                {activeStepName === CheckoutStep.Personalisation && (
                  <PersonalisationForm />
                )}
                {activeStepName === CheckoutStep.Extras && (
                  <ExtrasForm />
                )}
                {activeStepName === CheckoutStep.Team && (
                  <TeamForm />
                )}
                {activeStepName === CheckoutStep.Fundraising && (
                  <FundraisingForm />
                )}
                {activeStepName === CheckoutStep.Details && (
                  <DetailsForm />
                )}
                {activeStepName === CheckoutStep.Payment && (
                  <PaymentForm />
                )}

                <Pager
                  checkoutSteps={checkoutSteps}
                  activeStepIndex={activeStepIndex}
                  loading={loading}
                />
              </UI.Card>
            </UI.Form>
          )}

          {ticketsForSaleCount === 0 && (
            <UI.Card sc={{ strong: true }}>
              {t('currently_no_tickets_available')}
            </UI.Card>
          )}

          <UI.GridContainer sc={{ gutter: 0.25 }}>
            {event.enable_public_resale && (
              <UI.AButton
                href={`${event.resale_url}?locale=${locale}`}
                target="_blank"
                rel="noopener noreferrer"
                sc={{ blank: true, color: 'link', block: true }}
              >
                <UI.Icon>
                  <UI.Icons.Resale />
                </UI.Icon>
                {' '}
                {t('ticket_resale')}
                {event.cached_registrations_for_sale_count > 0 && ` (${formatNumber(event.cached_registrations_for_sale_count)})`}
              </UI.AButton>
            )}

            {event.has_invitation_codes && (
              <InvitationCodeForm />
            )}

            <UserInfo />
          </UI.GridContainer>
        </>
      )}
    </Layout>
  );
};

export default CheckoutPage;
