import { User } from 'react-feather';
import { useTranslation } from 'react-i18next';

import UI from '../../common/UI';

const UserInfo = () => {
  const { t } = useTranslation();

  return (
    <UI.AButton
      href="/me"
      target="_blank"
      rel="noopener noreferrer"
      sc={{ blank: true, color: 'link', block: true }}
    >
      <UI.Icon>
        <User />
      </UI.Icon>
      {' '}
      {t('manage_registration')}
    </UI.AButton>
  );
};

export default UserInfo;
