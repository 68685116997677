import styled, { css } from 'styled-components';

import UI from '../../common/UI';

interface PagerContainerProps {
  isSticky: boolean;
  isEmbedded: boolean;
  right: number | string;
  bottom: number | string;
  left: number | string;
}

const PagerContainer = styled(UI.Div)<PagerContainerProps>`
  ${({ isSticky, isEmbedded, right, bottom, left, theme }) => css`
    ${!isSticky && css`
      border-bottom-left-radius: ${theme.borderRadiuses.xl}px;
      border-bottom-right-radius: ${theme.borderRadiuses.xl}px;
    `}

    ${isEmbedded && css`
      @media ${theme.devices.md} {
        border-bottom-left-radius: ${theme.borderRadiuses.xl}px;
        border-bottom-right-radius: ${theme.borderRadiuses.xl}px;
      }
    `}

    > ${UI.Div} {
      padding: ${theme.gutter * 0.75}px;

      @media ${theme.devices.md} {
        padding-left: ${theme.gutter}px;
        padding-right: ${theme.gutter}px;

        ${isEmbedded && css`
          border-bottom-left-radius: ${theme.borderRadiuses.lg}px;
          border-bottom-right-radius: ${theme.borderRadiuses.lg}px;
        `}
      }
    }

    ${isSticky && css`
      position: fixed;
      right: ${right}px;
      bottom: ${bottom}px;
      left: ${left}px;
      z-index: 100;
      background: rgba(255, 255, 255, 0.95);
      backdrop-filter: blur(3px);
      border-top: 1px solid rgba(0, 0, 0, 0.08);

      > ${UI.Div} {
        max-width: ${theme.breakpoints.md - 2 * theme.gutter * 0.75}px;
        margin: 0 auto;
        padding-left: ${theme.gutter * 1.25}px;
        padding-right: ${theme.gutter * 1.25}px;

        @media ${theme.devices.md} {
          padding-left: ${theme.gutter}px;
          padding-right: ${theme.gutter}px;
          max-width: ${theme.breakpoints.md - 2 * theme.gutter}px;
        }
      }
    `}
  `}
`;

export default PagerContainer;
