
import { Locale } from '__generated__/graphql';
import { isSupportedLocale } from '../../i18n';
import { isValidLocale } from '../../common/helpers';
import useUrlState from '../../common/useUrlState';

export interface CheckoutUrlState {
  locale?: Locale | null,
  orderId?: string;
  orderToken?: string;
  paymentStatus?: string;
  ticketCategoryId?: string;
  coupon?: string;
  invitationCode?: string;
  charityId?: string;
  sourceUrl?: string;
  initialUrl?: string;
  finalUrl?: string;
  _ga?: string; // Google Analytic's Session ID used for cross-domain tracking.
}

const useCheckoutUrlState = () => useUrlState<CheckoutUrlState>({
  parse: (params, { string }) => ({
    locale: isValidLocale(params.locale) && isSupportedLocale(params.locale) ? params.locale : null,
    orderId: string(params.orderId),
    orderToken: string(params.orderToken),
    paymentStatus: string(params.paymentStatus),
    ticketCategoryId: string(params.ticketCategoryId),
    coupon: string(params.coupon),
    invitationCode: string(params.invitationCode),
    charityId: string(params.charityId),
    sourceUrl: string(params.sourceUrl),
    initialUrl: string(params.initialUrl),
    finalUrl: string(params.finalUrl),
    // eslint-disable-next-line no-underscore-dangle
    _ga: string(params._ga),
  }),
});

export default useCheckoutUrlState;
