import * as yup from 'yup';
import { ApolloError } from '@apollo/client';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useMemo } from 'react';

import {
  BusinessRegistrationInput,
  CompleteCheckoutInput,
  CreateRegistrationInput,
  GetCheckoutSummaryQuery as GetCheckoutSummary,
  ParticipantAttributes,
} from '__generated__/graphql';

import {
  CheckoutStep, Event, ParticipantField, Product, Session, getKeyedSellables, getRequiredParticipantAttributes,
  getTicketsForSale, getVisibleProductsForTicketPromotion,
} from './helpers';
import { PaymentMethod, PaymentMethodsInfo } from '../../common/usePaymentMethods';
import { PersonalisationInfo } from './usePersonalisationInfo';
import { calculateQuantities } from './useQuantities';
import { emailRegex, getServerErrors, zipCodeRegex } from '../../common/helpers';
import { getInvoiceSchema } from '../../common/Invoice/InvoiceDetailsForm';
import { getParticipantFieldEntrySchema } from '../../common/ParticipantFields/helpers';
import useValidation from '../../common/useValidation';

type CheckoutSummary = GetCheckoutSummary['checkoutSummary'];

const ticketsSchema = yup.object({
  registrations: yup.array().when(['business_registrations'], {
    is: (businessRegistrations: any[]) => businessRegistrations.length === 0,
    then: yup.array().min(1),
  }),
  business_registrations: yup.array(),
});

const teamSchema = yup.object({
  team: yup.object({
    id: yup.string(),
    title: yup.string().when(['id'], {
      is: (id: string) => !id,
      then: yup.string().required(),
    }),
  }).nullable(),
});

const getFundraisingSchema = (event: Event) => (
  yup.object({
    charity: event.enable_fundraising && event.require_fundraising
      && (event.allow_other_charity || event.charities.length > 1)
      ? yup.object().required()
      : yup.object().nullable().default(null),
  })
);

const getPersonalisationSchema = (event: Event, session: Session) => {
  const tickets = getTicketsForSale(event);
  const keyedTickets = getKeyedSellables(tickets);

  const getAttributeSchema = (attribute: ParticipantAttributes) => getRegistrationAttributeSchema(
    attribute,
    event,
  );

  return yup.object({
    registrations: yup.object({
      create: yup.array(
        yup.object({
          participant: yup.object({
            email: yup.string().ensure().required().matches(emailRegex, { name: 'email' }),
            first_name: yup.string().ensure().required(),
            last_name: yup.string().ensure().required(),
          }).nullable().default(null),
          details: yup.object({
            date_of_birth: (getAttributeSchema(ParticipantAttributes.date_of_birth) as any).after('1900-01-01').past(),
            gender: getAttributeSchema(ParticipantAttributes.gender),
            nationality: getAttributeSchema(ParticipantAttributes.nationality),
            street: getAttributeSchema(ParticipantAttributes.address),
            house_number: getAttributeSchema(ParticipantAttributes.address).when(['country'], {
              is: (country: string) => country === 'NL',
              then: yup.string().ensure().test('invalid', (value) => (
                // Dutch house numbers should contain at least one digit
                !value || !!value.match(/\d/)
              )),
            }),
            zip_code: getAttributeSchema(ParticipantAttributes.address).when(['country'], {
              is: (country: string) => country === 'NL',
              then: yup.string().ensure().test('zip_code', (value) => (
                // Remove non-alphanumeric characters and check if it matches 1111AA
                !value || !!value.replace(/[^0-9a-z]/gi, '').match(zipCodeRegex.NL)
              )),
            }),
            city: getAttributeSchema(ParticipantAttributes.address),
            country: getAttributeSchema(ParticipantAttributes.address),
            phone: getAttributeSchema(ParticipantAttributes.phone)
              .test('phone', '', (value: string) => !value || isValidPhoneNumber(value)),
            emergency_phone: getAttributeSchema(ParticipantAttributes.emergency_phone)
              .test('phone', '', (value: string) => !value || isValidPhoneNumber(value)),
          }).nullable().default(null),
          ticket: yup.object({
            id: yup.string(),
          }),
          purchase: yup.object({
            promotion: yup.object({
              id: yup.string(),
            }),
          }),
          fields: yup.array(getParticipantFieldEntrySchema(event.enabled_participant_fields)).nullable().default(null),
          upgrades: yup.array(
            yup.object({
              product: yup.object({
                id: yup.string(),
              }),
              purchase: yup.object({
                promotion: yup.object({
                  id: yup.string(),
                }),
              }),
              fields: yup.array(getParticipantFieldEntrySchema(event.enabled_participant_fields))
                .nullable().default(null),
            }),
          ),
        }).test((value, context) => {
          const { promotion } = value.purchase;
          const ticket = keyedTickets[promotion.id];

          if (!ticket) {
            // Ticket might not exist anymore, e.g. after unsetting invitation code
            return true;
          }

          const registration: CreateRegistrationInput = {
            ticket: { id: ticket.id },
            purchase: { promotion: { id: promotion.id } },
            fields: [],
            upgrades: value.upgrades.map((value) => ({
              product: { id: value.product.id },
              purchase: { promotion: { id: value.purchase.promotion.id } },
              fields: [],
            })),
          };

          const availableProducts = getVisibleProductsForTicketPromotion(promotion, event.products_for_sale);

          const quantities = calculateQuantities({
            registrations: [registration],
            ticketCategories: event.ticket_categories,
            products: availableProducts,
          });

          const missingProducts = availableProducts.filter(
            (product) => quantities.products[product.id] < product.min_per_ticket,
          );

          if (missingProducts.length > 0) {
            return context.createError({
              type: 'required_upgrades',
              params: { products: missingProducts },
            });
          }

          return true;
        }).test((value, context) => {
          const { promotion } = value.purchase;
          const ticket = keyedTickets[promotion.id];

          if (!ticket) {
            // Ticket might not exist anymore, e.g. after unsetting invitation code
            return true;
          }

          const availableProducts = getVisibleProductsForTicketPromotion(promotion, event.products_for_sale);
          const missingProducts = availableProducts.filter(
            (product) => product.donation && (!session || typeof session.donate[product.id] === 'undefined'),
          );

          if (missingProducts.length > 0) {
            return context.createError({
              type: 'indicate_donation',
              params: { products: missingProducts },
            });
          }

          return true;
        }),
      ),
    }),
  });
};

/**
 * A Yup schema for attributes of assigned registrations.
 */
const getRegistrationAttributeSchema = (
  attribute: ParticipantAttributes,
  event: Event,
) => (
  yup.string().ensure().test('required', '', (value, context) => {
    // Access the attribute's grandparent, which is the registration object
    const registration: CreateRegistrationInput = (context as any).from[1].value;
    const requiredParticipantAttributes = getRequiredParticipantAttributes(registration, event);
    const required = requiredParticipantAttributes.includes(attribute);

    return !required || !!value;
  })
);

function getExtrasSchema(
  event: Event,
  standAloneProducts: Product[],
  donate: Session['donate'],
) {
  const tickets = getTicketsForSale(event);
  const keyedTickets = getKeyedSellables(tickets);

  return yup.object({
    registrations: yup.object({
      business: yup.array(
        yup.object({
          promotion: yup.object({
            id: yup.string(),
          }),
          quantity: yup.number(),
          upgrades: yup.array(
            yup.object({
              product: yup.object({
                id: yup.string(),
              }),
              purchase: yup.object({
                promotion: yup.object({
                  id: yup.string(),
                }),
              }),
            }),
          ),
        }).test((value, context) => {
          const { promotion } = value;
          const ticket = keyedTickets[promotion.id];

          const registration: BusinessRegistrationInput = {
            promotion: { id: promotion.id },
            quantity: value.quantity,
            upgrades: value.upgrades.map((value) => ({
              product: { id: value.product.id },
              purchase: { promotion: { id: value.purchase.promotion.id } },
              fields: [],
            })),
          };

          const quantities = calculateQuantities({
            businessRegistrations: [registration],
            ticketCategories: event.ticket_categories,
            products: event.products_for_sale,
          });

          if (!ticket) {
            // Ticket might not exist anymore, e.g. after unsetting invitation code
            return true;
          }

          const availableProducts = getVisibleProductsForTicketPromotion(promotion, event.products_for_sale);
          const missingProducts = availableProducts.filter(
            (product) => quantities.products[product.id] < product.min_per_ticket,
          );

          if (missingProducts.length > 0) {
            return context.createError({
              type: 'required_upgrades',
              params: { products: missingProducts },
            });
          }

          return true;
        }).test((value, context) => {
          const { promotion } = value;
          const ticket = keyedTickets[promotion.id];

          if (!ticket) {
            // Ticket might not exist anymore, e.g. after unsetting invitation code
            return true;
          }

          const availableProducts = getVisibleProductsForTicketPromotion(promotion, event.products_for_sale);
          const missingProducts = availableProducts.filter(
            (product) => product.donation && typeof donate[product.id] === 'undefined',
          );

          if (missingProducts.length > 0) {
            return context.createError({
              type: 'indicate_donation',
              params: { products: missingProducts },
            });
          }

          return true;
        }),
      ),
      stand_alone_upgrades: yup.array(
        yup.object({
          product: yup.object({
            id: yup.string(),
          }),
          purchase: yup.object({
            promotion: yup.object({
              id: yup.string(),
            }),
          }),
          fields: yup.array(getParticipantFieldEntrySchema(event.enabled_participant_fields)).nullable().default(null),
        }),
      ).test((value, context) => {
        const quantities = calculateQuantities({
          standAloneUpgrades: value.map((upgrade) => ({
            purchase: { promotion: { id: upgrade.purchase.promotion.id } },
            product: { id: upgrade.product.id },
          })),
          products: standAloneProducts,
        });

        const missingProducts = standAloneProducts.filter(
          (product) => quantities.products[product.id] < product.min_per_order,
        );

        if (missingProducts.length > 0) {
          return context.createError({
            type: 'required_upgrades',
            params: { products: missingProducts },
          });
        }

        return true;
      }).test((value, context) => {
        const missingProducts = standAloneProducts.filter(
          (product) => product.donation && typeof donate[product.id] === 'undefined',
        );

        if (missingProducts.length > 0) {
          return context.createError({
            type: 'indicate_donation',
            params: { products: missingProducts },
          });
        }

        return true;
      }),
    }),
  });
}

const getDetailsSchema = (
  requireParticipantDetails: boolean,
  requireInvoiceDetails: boolean,
  requireInvoicePhone: boolean,
  participantFields: ParticipantField[],
) => yup.object({
  ...requireParticipantDetails ? {
    participant: yup.object({
      email: yup.string()
        .required()
        .matches(emailRegex, { name: 'email' }),
      first_name: yup.string().ensure().required(),
      last_name: yup.string().ensure().required(),
    }),
  } : {},
  ...requireInvoiceDetails ? {
    invoice: getInvoiceSchema(requireInvoicePhone),
  } : {},
  fields: yup.array(getParticipantFieldEntrySchema(participantFields)).nullable().default(null),
});

const getPaymentMethodSchema = (required: boolean, allowedPaymentMethods: string[]) => yup.object({
  payment_method: (required ? yup.string().ensure().required() : yup.string().ensure()).test('invalid', (value) => (
    !value || allowedPaymentMethods.includes(value as PaymentMethod)
  )),
});

const getPaymentSchema = (
  event: Event,
  requirePayment: boolean,
  allowedPaymentMethods: string[],
) => (
  yup.object({
    coupon: yup.string().nullable(),
    payment_method: getPaymentMethodSchema(requirePayment, allowedPaymentMethods),
    terms: yup.boolean().test('required', '', (value) => (
      event.terms || event.terms_url || event.terms_text ? value : true
    )),
  })
);

interface UseValidatorsProps {
  form: CompleteCheckoutInput;
  session: Session;
  event: Event;
  personalisation: PersonalisationInfo;
  standAloneProducts: Product[];
  requireInvoiceDetails: boolean;
  checkoutSummary: CheckoutSummary;
  paymentMethods: PaymentMethodsInfo;
  error: ApolloError;
}

const useValidators = ({
  form,
  session,
  event,
  personalisation,
  standAloneProducts,
  requireInvoiceDetails,
  checkoutSummary,
  paymentMethods,
  error,
}: UseValidatorsProps) => {
  const serverErrors = useMemo(() => getServerErrors(error), [error]);

  const requireParticipantDetails = personalisation.personalRegistrations.length === 0;
  const requireInvoicePhone = requireInvoiceDetails
    && event.participant_attributes.includes(ParticipantAttributes.phone);
  const invoiceDetails = session.invoiceDetails || requireInvoiceDetails ? form.invoice : {};
  const requirePaymentMethod = checkoutSummary.amount > 0;

  const personalisationSchema = useMemo(
    () => getPersonalisationSchema(event, session),
    [event, session],
  );

  const extrasSchema = useMemo(
    () => getExtrasSchema(event, standAloneProducts, session.donate),
    [event, standAloneProducts, session.donate],
  );

  const detailsSchema = useMemo(
    () => getDetailsSchema(
      requireParticipantDetails,
      session.invoiceDetails || requireInvoiceDetails,
      requireInvoicePhone,
      event.enabled_participant_fields,
    ),
    [
      requireParticipantDetails,
      session.invoiceDetails,
      requireInvoiceDetails,
      requireInvoicePhone,
      event.enabled_participant_fields,
    ],
  );

  const fundraisingSchema = useMemo(
    () => getFundraisingSchema(event),
    [event],
  );

  const paymentSchema = useMemo(
    () => getPaymentSchema(event, requirePaymentMethod, paymentMethods.allowedPaymentMethods),
    [event, requirePaymentMethod, paymentMethods.allowedPaymentMethods],
  );

  return {
    [CheckoutStep.Tickets]: useValidation({
      schema: ticketsSchema,
      values: {
        registrations: form.registrations.create,
        business_registrations: form.registrations.business,
      },
      externalErrors: serverErrors,
    }),
    [CheckoutStep.Personalisation]: useValidation({
      schema: personalisationSchema,
      values: {
        registrations: {
          create: form.registrations.create.map((registration, index) => ({
            ...registration,
            // Unset the assignee fields when the registration is unassigned or skipped.
            ...(!registration.participant || !personalisation.personalisedFormIndices.includes(index) ? {
              participant: null,
              details: null,
              fields: null,
            } : {}),
          })),
        },
      },
      externalErrors: serverErrors,
    }),
    [CheckoutStep.Extras]: useValidation({
      schema: extrasSchema,
      values: {
        registrations: {
          business: form.registrations.business,
          stand_alone_upgrades: form.registrations.stand_alone_upgrades,
        },
      },
      externalErrors: serverErrors,
    }),
    [CheckoutStep.Team]: useValidation({
      schema: teamSchema,
      values: { team: form.team },
      externalErrors: serverErrors,
    }),
    [CheckoutStep.Fundraising]: useValidation({
      schema: fundraisingSchema,
      values: { charity: form.charity },
      externalErrors: serverErrors,
    }),
    [CheckoutStep.Details]: useValidation({
      schema: detailsSchema,
      values: {
        participant: form.participant,
        fields: form.fields,
        invoice: invoiceDetails,
      },
      externalErrors: serverErrors,
    }),
    [CheckoutStep.Payment]: useValidation({
      schema: paymentSchema,
      values: {
        invoice: invoiceDetails,
        coupon: form.coupon,
        payment_method: form.payment_method,
        terms: session.agreedToTerms,
      },
      externalErrors: serverErrors,
    }),
  };
};

export default useValidators;
