import { ApolloError } from '@apollo/client';
import { Clock } from 'react-feather';
import { Trans } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { EmbedContext } from '../../Common/EmbedProvider';
import { getKeyedSellables, getTicketsForSale } from '../helpers';
import { getServerErrors } from '../../../common/helpers';
import { useEvent } from '../EventProvider';
import { useTracking } from '../../../common/Tracking';
import CheckoutContext from '../CheckoutContext';
import RegistrationForm from './RegistrationForm';
import UI from '../../../common/UI';
import useLocale from '../../../common/useLocale';
import useMediaDevice from '../../../common/useMediaDevice';
import useStickyElement from '../../../common/useStickyElement';
import useTimeSlotFormatter from '../../../common/Tickets/useTimeSlotFormatter';

/**
 * Shows the form for the currently active registration.
 */
const PersonalisationForm = () => {
  const { track } = useTracking();

  const {
    personalisation,
    setActiveRegistration,
    error,
  } = useContext(CheckoutContext);

  const registrations = personalisation.personalisableRegistrations;
  const registration = personalisation.activeRegistration;
  const total = registrations.length;

  // 'undefined' as a string because the effect below should also run on component mount.
  const errorRef = useRef<ApolloError | 'undefined'>('undefined');

  // If there is a server error, activate the first registration form with an error,
  // or simply the first if there are no errors
  useEffect(() => {
    if (error !== errorRef.current) {
      const errors = getServerErrors(error);

      // These point to indices in the form state
      const serverIndices = error
        // Extract the registration index from the error key
        ? Object.keys(errors).map((key) => {
          const match = key.match(/^registrations\.create\.([0-9]*)\./);
          return match?.length > 1 ? parseInt(match[1], 10) : -1;
        }).filter((index) => index > -1)
        : [];

      // Map them to indices of personalisable registrations
      const personalisableIndices = serverIndices.map((index) => (
        personalisation.personalisableFormIndices.findIndex((i) => i === index)
      ));

      setActiveRegistration(personalisableIndices.length > 0 ? Math.max(Math.min(...personalisableIndices), 0) : 0);
    }

    errorRef.current = error;
  }, [error, setActiveRegistration, personalisation.personalisableFormIndices]);

  useEffect(() => {
    track?.('Checkout:RegistrationOpened', { index: personalisation.activeFormIndex, total });
  }, [track, total, personalisation.activeFormIndex]);

  if (!registration) {
    return null;
  }

  return (
    <>
      <Header />
      <RegistrationForm key={personalisation.activeFormIndex} />
    </>
  );
};

const Header = () => {
  const { formatNumber } = useLocale();
  const formatTimeSlot = useTimeSlotFormatter();
  const { event } = useEvent();

  const { personalisation } = useContext(CheckoutContext);

  const registration = personalisation.activeRegistration;
  const total = personalisation.personalisableRegistrations.length;

  const { embedded } = useContext(EmbedContext);
  const device = useMediaDevice();

  const { anchorRef, elementRef, isSticky, position, reset } = useStickyElement({
    containerId: 'CheckoutContainer',
    scrollContainerId: embedded && !device.isSmall ? 'PageContainer' : undefined,
    offset: embedded ? 48 : 0,
    floatingElementHeight: 54,
  });

  useEffect(() => {
    reset();
  }, [personalisation.activeIndex, reset]);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = window.setTimeout(() => {
      setProgress(((personalisation.activeIndex + 1) / total) * 100);
    }, 300);

    return () => window.clearTimeout(timer);
  }, [personalisation.activeIndex, total]);

  const tickets = getTicketsForSale(event);
  const ticket = getKeyedSellables(tickets)[registration.purchase.promotion.id];

  const promotion = ticket.promotions_for_sale.filter(
    (promotion) => promotion.id === registration.purchase.promotion.id,
  )[0];

  const timeSlot = ticket.upcoming_time_slots.filter(
    (timeSlot) => timeSlot.id === registration.time_slot?.id,
  )[0];

  return (
    <UI.FadeIn sc={{ background: 'gray.50', padding: 0 }} ref={anchorRef}>
      <HeaderContainer ref={elementRef} isSticky={isSticky} {...position}>
        <UI.GridContainer sc={{ gutter: 0.5 }}>
          <UI.Div>
            <UI.FlexContainer sc={{ justifyContent: 'space-between' }} key={personalisation.activeIndex}>
              <UI.FadeIn>
                <UI.H4 sc={{ strong: true, small: isSticky, mr: 2 }}>
                  <UI.Icon>
                    <UI.Icons.Ticket />
                  </UI.Icon>
                  {' '}
                  {ticket.title}
                </UI.H4>
              </UI.FadeIn>
              {isSticky && (
                <UI.Small>
                  <UI.Badge
                    sc={{ brand: 'secondary', my: -0.5 }}
                    style={{
                      minWidth: 'auto',
                      padding: '0 .75em',
                      lineHeight: 1.83,
                      height: '2em',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Trans
                      i18nKey="personalisation_form.ticket_x_of_y"
                      values={{ x: formatNumber(personalisation.activeIndex + 1), y: formatNumber(total) }}
                    >
                      <UI.Strong />
                      <UI.Strong />
                    </Trans>
                  </UI.Badge>
                </UI.Small>
              )}
            </UI.FlexContainer>
            {!isSticky && (promotion.title || timeSlot) && (
              <UI.Div sc={{ muted: true, mt: 1 }}>
                <UI.Delimit>
                  {promotion.title}
                  {timeSlot && (
                    <UI.Span>
                      <UI.Icon>
                        <Clock />
                      </UI.Icon>
                      {' '}
                      <UI.Delimit>
                        {formatTimeSlot(timeSlot)}
                        {timeSlot.title}
                      </UI.Delimit>
                    </UI.Span>
                  )}
                </UI.Delimit>
              </UI.Div>
            )}
          </UI.Div>
          {!isSticky && (
            <UI.Div>
              <UI.Div sc={{ background: 'gray.200', mb: 1 }} style={{ height: 4 }}>
                <UI.Div
                  sc={{ background: 'secondary' }}
                  style={{
                    height: 4,
                    width: `${progress}%`,
                    transition: 'all 0.5s ease-in-out',
                  }}
                />
              </UI.Div>
              <UI.Span
                style={{ opacity: progress > 0 ? 1 : 0.3, transition: 'all 0.15s ease-in-out' }}
              >
                <Trans
                  i18nKey="personalisation_form.personalising_ticket_x_of_y"
                  values={{ count: formatNumber(personalisation.activeIndex + 1), total: formatNumber(total) }}
                >
                  <UI.Strong />
                  <UI.Strong />
                </Trans>
              </UI.Span>
            </UI.Div>
          )}
        </UI.GridContainer>
      </HeaderContainer>
    </UI.FadeIn>
  );
};

interface HeaderContainerProps {
  isSticky: boolean;
  top: number | string;
  right: number | string;
  left: number | string;
}

const HeaderContainer = styled(UI.Div)<HeaderContainerProps>`
  ${({ isSticky, top, right, left, theme }) => css`
    background: ${theme.colors.gray[50]};

    > ${UI.Div} {
      padding: ${theme.gutter * 0.75}px;

      @media ${theme.devices.md} {
        padding-left: ${theme.gutter}px;
        padding-right: ${theme.gutter}px;
      }
    }

    ${isSticky && css`
      position: fixed;
      top: ${top}px;
      left: ${left}px;
      right: ${right}px;
      z-index: 100;
      border-bottom: 1px solid rgba(0, 0, 0, .08);

      > ${UI.Div} {
        padding-left: ${theme.gutter * 1.25}px;
        padding-right: ${theme.gutter * 1.25}px;

        @media ${theme.devices.md} {
          padding-left: ${theme.gutter}px;
          padding-right: ${theme.gutter}px;
          max-width: ${theme.breakpoints.md - 2 * theme.gutter * 0.75}px;
          margin: 0 auto;

          @media ${theme.devices.md} {
            padding-left: ${theme.gutter}px;
            padding-right: ${theme.gutter}px;
            max-width: ${theme.breakpoints.md - 2 * theme.gutter}px;
          }
        }
      }
    `}
  `}
`;

export default PersonalisationForm;
