import { ArrowUpRight } from 'react-feather';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import keyBy from 'lodash/keyBy';

import { ParticipantAttributes } from '__generated__/graphql';

import { EmbedContext } from '../../Common/EmbedProvider';
import { containsKeyPrefix, filterPrefix } from '../../../common/helpers';
import { useEvent } from '../EventProvider';
import CheckoutContext from '../CheckoutContext';
import EmailInput from '../../../common/ParticipantAttributes/Attributes/EmailInput';
import InvoiceDetailsForm from '../../../common/Invoice/InvoiceDetailsForm';
import NameInput from '../../../common/ParticipantAttributes/Attributes/NameInput';
import ParticipantFieldInput from '../../../common/ParticipantFields/ParticipantFieldInput';
import UI from '../../../common/UI';
import useMemoizedValue from '../../../common/useMemoizedValue';

interface Participant {
  email: string;
  first_name: string;
  last_name: string;
}

/**
 * Asks the user to log in or create a new account, then shows the form with personal details.
 */
const DetailsForm = () => {
  const { t } = useTranslation();
  const { event } = useEvent();

  const {
    form,
    session,
    paymentMethods: {
      bankTransferEnabled,
    },
    editParticipant,
    editOrderField,
    toggleInvoice,
    editInvoiceDetails,
    requireInvoiceDetails,
    touch,
    touched,
    validators: {
      details: { errors, valid },
    },
  } = useContext(CheckoutContext);

  const getAssignees = () => {
    const assignees: Participant[] = [];

    form.registrations.create.forEach(({ participant }) => {
      if (participant) {
        if (participant.email && participant.first_name && participant.last_name) {
          const assignee: Participant = {
            email: participant.email,
            first_name: participant.first_name,
            last_name: participant.last_name,
          };

          if (!assigneeExists(assignee, assignees)) {
            assignees.push(assignee);
          }
        }
      }
    });

    return assignees;
  };

  const assigneeExists = (participant: Partial<Participant>, assignees: Participant[]) => (
    assignees.filter((assignee) => (
      assignee.email.toLowerCase() === participant?.email?.toLowerCase()
        && assignee.first_name.toLowerCase() === participant?.first_name?.toLowerCase()
        && assignee.last_name.toLowerCase() === participant?.last_name?.toLowerCase()
    )).length > 0
  );

  const assignees = useMemoizedValue(getAssignees());
  const hasAssigneeCandidates = assignees.length > 0;

  /** If true, the buyer has selected one of the assignees */
  const hasSelectedAssignee = hasAssigneeCandidates && assigneeExists(form.participant, assignees);

  /** If true, the buyer has selected the 'Other...' choice */
  const hasEnteredParticipantDetails = !hasSelectedAssignee && (
    !!form.participant.email || !!form.participant.first_name || !!form.participant.last_name
  );

  /**
   * If true, name and e-mail must be entered, is initially true if there
   * are no candidates or if details have been entered before
   */
  const [requireParticipantDetails, setRequireParticipantDetails] = useState(
    !hasAssigneeCandidates || hasEnteredParticipantDetails,
  );

  const assigneeIsSelected = (assignee: Participant) => !requireParticipantDetails && (
    assignee.email.toLowerCase() === form.participant.email?.toLowerCase()
      && assignee.first_name.toLowerCase() === form.participant.first_name?.toLowerCase()
      && assignee.last_name.toLowerCase() === form.participant.last_name?.toLowerCase()
  );

  const selectAssignee = useCallback((assignee: Participant) => {
    editParticipant((participant) => ({
      ...participant,
      ...assignee,
    }));

    setRequireParticipantDetails(false);
  }, [editParticipant]);

  const selectSomeoneElse = () => {
    editParticipant((participant) => ({
      ...participant,
      email: null,
      first_name: null,
      last_name: null,
    }));

    setRequireParticipantDetails(true);
  };

  // Pre-select the first assignee if none is selected
  useEffect(() => {
    if (!hasSelectedAssignee && !requireParticipantDetails && assignees.length > 0) {
      selectAssignee(assignees[0]);
    }
  }, [hasSelectedAssignee, requireParticipantDetails, assignees, selectAssignee]);

  const requirePhone = requireInvoiceDetails && event.participant_attributes.includes(ParticipantAttributes.phone);

  const keyedFields = keyBy(event.enabled_participant_fields, 'id');

  const { scrollToSection, scrollTop } = useContext(EmbedContext);

  const scrollToErrorFields = () => {
    const hasParticipantError = containsKeyPrefix(errors, 'participant');
    const hasInvoiceError = containsKeyPrefix(errors, 'invoice');
    const hasFieldsError = containsKeyPrefix(errors, 'fields');

    if (hasParticipantError) {
      scrollToSection('ParticipantSection');
    } else if (hasInvoiceError) {
      scrollToSection('InvoiceSection');
    } else if (hasFieldsError) {
      scrollToSection('FieldsSection');
    } else {
      scrollTop();
    }

    Object.keys(errors).forEach((key: string) => touch(key));
  };

  return (
    <UI.FadeIn>
      <UI.FormGrid>
        <UI.Legend id="ParticipantSection">
          {t('details_form.buyer_details')}
        </UI.Legend>

        {hasAssigneeCandidates && (
          <>
            <UI.Div>
              {t('details_form.contact_details_description')}
            </UI.Div>

            <UI.FormGrid sc={{ gutter: 0.25 }}>
              {assignees.map((assignee, index) => (
                <UI.Radio
                  checked={assigneeIsSelected(assignee)}
                  onChange={() => selectAssignee(assignee)}
                  sc={{ box: true, block: true }}
                  name="buyer"
                  key={index}
                >
                  <UI.Strong>
                    {assignee.first_name}
                    {' '}
                    {assignee.last_name}
                  </UI.Strong>
                  <UI.Div>
                    {assignee.email}
                  </UI.Div>
                </UI.Radio>
              ))}

              <UI.Radio
                checked={requireParticipantDetails}
                onChange={() => selectSomeoneElse()}
                sc={{ box: true, block: true }}
                name="buyer"
              >
                <UI.Strong>
                  {t('details_form.other')}
                </UI.Strong>
                <UI.Div>
                  {t('details_form.enter_contact_details')}
                </UI.Div>
              </UI.Radio>
            </UI.FormGrid>
          </>
        )}

        {requireParticipantDetails && (
          <UI.FadeIn>
            <UI.FormGrid>
              <NameInput
                value={form.participant}
                onChange={(value) => editParticipant((participant) => ({ ...participant, ...value }))}
                onBlur={(field) => touch(`participant.${field}`)}
                touched={touched('participant')}
                errors={filterPrefix(errors, 'participant')}
              />

              <EmailInput
                value={form.participant}
                onChange={(value) => editParticipant((participant) => ({ ...participant, ...value }))}
                onBlur={(field) => touch(`participant.${field}`)}
                touched={touched('participant')}
                errors={filterPrefix(errors, 'participant')}
                description={t('email_will_receive_confirmation')}
              />
            </UI.FormGrid>
          </UI.FadeIn>
        )}

        {form.fields.length > 0 && (
          <>
            <UI.HR />
            <UI.Legend id="FieldsSection">
              {t('details_form.additional_info')}
            </UI.Legend>
            {form.fields.map((value, index) => keyedFields[value.participant_field.id] && (
              <ParticipantFieldInput
                value={value}
                onChange={(newValue) => editOrderField((oldValue) => ({ ...oldValue, ...newValue }), index)}
                onBlur={() => touch(`fields.${index}`)}
                touched={!!touched(`fields.${index}`)}
                field={keyedFields[value.participant_field.id]}
                errors={filterPrefix(errors, `fields.${index}`)}
                key={value.participant_field.id}
              />
            ))}
          </>
        )}

        <UI.HR />

        <UI.Legend id="InvoiceSection">
          {t('invoice_details')}
        </UI.Legend>

        <UI.FormGrid>
          {!requireInvoiceDetails && (
            <UI.Checkbox
              checked={session.invoiceDetails}
              onChange={() => toggleInvoice()}
            >
              {t('details_form.place_order_as_company')}
              {bankTransferEnabled && event.require_company_for_bank_transfer
                && ` ${t('details_form.required_for_bank_transfer')}`}
            </UI.Checkbox>
          )}

          <UI.AnimateHeight isVisible={session.invoiceDetails || requireInvoiceDetails}>
            <InvoiceDetailsForm
              value={form.invoice}
              onChange={(value) => editInvoiceDetails((invoice) => ({ ...invoice, ...value }))}
              onBlur={(field) => touch(`invoice.${field}`)}
              touched={touched('invoice')}
              errors={filterPrefix(errors, 'invoice')}
              requirePhone={requirePhone}
            />
          </UI.AnimateHeight>
        </UI.FormGrid>

        {!valid && (
          <UI.FadeIn>
            <UI.Warning>
              <UI.A
                onClick={() => scrollToErrorFields()}
                sc={{ secondary: true, block: true }}
                style={{ fontWeight: 500 }}
                role="button"
              >
                {t('fill_required_fields')}
                {' '}
                <UI.Icon>
                  <ArrowUpRight />
                </UI.Icon>
              </UI.A>
            </UI.Warning>
          </UI.FadeIn>
        )}
      </UI.FormGrid>
    </UI.FadeIn>
  );
};

export default DetailsForm;
