import { ReactNode, useContext, useEffect, useState } from 'react';
import { X } from 'react-feather';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { EmbedContext } from './EmbedProvider';
import { assetUrl } from '../../common/helpers';
import DefaultStyle from './DefaultStyle';
import SC from '../../common/UI/SC';
import UI from '../../common/UI';

const OpenEmbedButton = () => {
  const { t } = useTranslation();

  const { setOpen } = useContext(EmbedContext);

  const openEmbed = () => {
    setOpen(true);
  };

  return (
    <UI.FadeIn>
      <UI.AButton
        onClick={() => openEmbed()}
        sc={{ brand: 'secondary' }}
        style={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          paddingTop: 6.5,
          paddingBottom: 6.5,
          border: '2px solid rgba(0, 0, 0, 0.05)',
          lineHeight: '20px',
        }}
        role="button"
      >
        <img
          src={assetUrl('images/logo-symbol-white.svg')}
          alt="Atleta"
          style={{
            width: 14, marginTop: 2, marginBottom: -2, marginRight: 4, opacity: 0.4,
          }}
        />
        {' '}
        {t('register')}
      </UI.AButton>
    </UI.FadeIn>
  );
};

const CloseEmbedButtonContainer = styled(UI.Div)`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: ${theme.transparentize(theme.mix(theme.colors.secondary[100], theme.colors.gray[100], 0.9), 0.05)};
    backdrop-filter: blur(3px);
    z-index: 2;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    z-index: 101;

    ${UI.H5} {
      padding: 0 52px 0 ${theme.gutter * 0.5}px;
      line-height: 48px;
      margin: 0;
      color: ${theme.colors.gray[800]}
    }

    ${UI.A} {
      font-size: 1.2em;
      color: ${theme.colors.gray[800]};
      display: inline-block;
      padding: ${theme.gutter * 0.5}px;
      transition: all 0.15s ease-in-out;
      border-top-right-radius: ${theme.borderRadiuses.lg}px;
      opacity: 0.5;
      position: absolute;
      top: 0;
      right: 0;

      &:hover {
        opacity: 1;
      }
    }

    @media ${theme.devices.md} {
      position: absolute;
      top: ${theme.gutter}px;
      right: ${theme.gutter}px;
      left: ${theme.gutter}px;
      border-top-left-radius: ${theme.borderRadiuses.lg}px;
      border-top-right-radius: ${theme.borderRadiuses.lg}px;

      ${UI.A}, ${UI.H5} {
        padding-left: ${theme.gutter}px;
        padding-right: ${theme.gutter}px;
      }
    }
  `}
`;

const CloseEmbedButton = () => {
  const { setOpen } = useContext(EmbedContext);

  const closeEmbed = () => {
    window.scrollTo(0, 0);
    setOpen(false);
  };

  return (
    <CloseEmbedButtonContainer id="CheckoutTitleBar">
      <UI.H5>
        <UI.Truncate>
          <img
            src={assetUrl('images/logo-symbol-black.svg')}
            alt="Atleta"
            style={{
              width: 14, marginTop: 2, marginBottom: -2, marginRight: 4, opacity: 0.3,
            }}
          />
        </UI.Truncate>
      </UI.H5>
      <UI.A onClick={() => closeEmbed()}>
        <UI.Icon>
          <X />
        </UI.Icon>
      </UI.A>
    </CloseEmbedButtonContainer>
  );
};

interface OuterContainerProps {
  open?: boolean;
}

const OuterContainer = styled(UI.Div)<SC<OuterContainerProps>>`
  ${({ sc: { open } = {}, theme }) => css`
    height: 40px;
    max-width: 572px;
    max-height: 100%;
    position: relative;

    @media ${theme.devices.md} {
      transition: height 0.15s ease-in-out;
    }

    ${open && css`
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      right: 0;

      @media ${theme.devices.md} {
        padding: ${theme.gutter}px;
      }
    `}
  `}
`;

const InnerContainer = styled(UI.Div)`
  ${({ theme }) => css`
    background: ${theme.mix(theme.colors.secondary[50], theme.colors.gray[50], 0.9)};
    min-width: 320px;
    padding-top: ${theme.gutter * 2}px;
    min-height: 100%;

    @media ${theme.devices.md} {
      width: 100%;
      height: 100%;
      border-radius: ${theme.borderRadiuses.lg}px;
      border-bottom-right-radius: ${theme.borderRadiuses.lg}px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15), 0 4px 16px rgba(0, 0, 0, 0.1);
      overflow-y: auto;
    }
  `}
`;

interface EmbedContainerProps {
  showRegisterButton?: boolean;
  children?: ReactNode;
}

const EmbedContainer = ({ showRegisterButton, children }: EmbedContainerProps) => {
  const { open } = useContext(EmbedContext);
  const [showingWindow, setShowingWindow] = useState(false);
  const [showingContent, setShowingContent] = useState(false);
  const [showingOpenButton, setShowingOpenButton] = useState(true);

  /**
   * For a smooth experience we first slide in the window, then fade in the content.
   * When the window is closed, show the button after a while.
   */
  useEffect(() => {
    setShowingContent(false);
    setShowingWindow(false);

    if (open) {
      setShowingOpenButton(false);

      const windowTimer = setTimeout(() => {
        setShowingWindow(true);
      }, 50);

      const contentTimer = setTimeout(() => {
        setShowingContent(true);
      }, 200);

      return () => {
        clearTimeout(windowTimer);
        clearTimeout(contentTimer);
      };
    }

    const buttonTimer = setTimeout(() => {
      setShowingOpenButton(true);
    }, 500);

    return () => clearTimeout(buttonTimer);
  }, [open]);

  return (
    <OuterContainer sc={{ open: showingWindow }}>
      {showRegisterButton && showingOpenButton && (
        <OpenEmbedButton />
      )}
      {showingWindow && (
        <>
          <InnerContainer id="PageContainer">
            {showingContent && children}
          </InnerContainer>
          <CloseEmbedButton />
        </>
      )}
    </OuterContainer>
  );
};

export interface WidgetContainerProps {
  showRegisterButton?: boolean;
  children?: ReactNode;
}

const WidgetContainer = ({ showRegisterButton, children }: WidgetContainerProps) => {
  const { embedded } = useContext(EmbedContext);

  return (
    <>
      {embedded && (
        <EmbedContainer showRegisterButton={showRegisterButton}>
          {children}
        </EmbedContainer>
      )}
      {!embedded && (
        <>
          <DefaultStyle />
          {children}
        </>
      )}
    </>
  );
};

export default WidgetContainer;
